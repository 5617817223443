let origin = "https://future.zhuneng.cn";
//发表社区共建
const postWishUrl = `/placeApi/front/communityConstruction/add`;
//我的社区共建
const myWishUrl = `/placeApi/front/communityConstruction/mineList`;
//共建详情
const wishDetailUrl = `/placeApi/front/communityConstruction/detailInfo`;
//社区共建墙
const wishWallUrl = `/placeApi/front/communityConstruction/list`;
export { postWishUrl, myWishUrl, wishDetailUrl, wishWallUrl };
