const createType = [
  {
    key: 1,
    value: "卫生",
  },
  {
    key: 2,
    value: "活动",
  },
  {
    key: 3,
    value: "公益",
  },
  {
    key: 4,
    value: "安全",
  },
];

const auditStatusMap = [
  { key: 1, value: "审核通过" },
  { key: 2, value: "审核驳回" },
  { key: 0, value: "待审核" },
];

export { createType, auditStatusMap };
